import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import Hero from '../components/Hero/Hero'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import { graphql, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import StickyBox from '../components/StickyBox/StickyBox'

import "./blog-list.scss";

export default function Blog({data, pageContext}) {
  let articlesCount = 8;
  const storagePosts = data.allWpPost.edges.slice(articlesCount);

  if(typeof window !== 'undefined'){
    sessionStorage.setItem('articles', JSON.stringify(storagePosts));
  }
  if(typeof document !== `undefined`) {
    document.addEventListener('scroll', function(e) {
      if(Boolean(document.getElementsByClassName('infinite-scroll').length) && typeof window !== 'undefined') {
        let scrollPosition = window.scrollY;
        var lastArticle = document.querySelector('.article-box:last-child');
        
        if(scrollPosition > lastArticle.offsetTop - lastArticle.clientHeight) {
          let articlesList = JSON.parse(sessionStorage.getItem('articles'));
          let articlesToShow = articlesList.slice(0, articlesCount);
          
          sessionStorage.setItem('articles', JSON.stringify(articlesList.slice(articlesCount)));

          let html = '';

          articlesToShow.forEach(post => {
              const image = getImage(post.node.featuredImage.node.localFile); 
              let imageUrl = image.images.fallback.src.replace(/(\s)/, "\\ ");
              html += `<div class="col-md-6 article-box">
                            <div class="article">
                              <a href="/blog/`+ post.node.slug +`/" title="`+ post.node.title +`">
                                  <div class="post-img" style="background-image: url('`+ imageUrl +`')"></div>
                                  <div class="post-content">
                                    <div class="post-info">
                                        <span class="add-date">`+ post.node.dateGmt +`</span>
                                        <span class="edit-date">`+ post.node.modifiedGmt +`</span>
                                    </div>
                                    <h2 class="post-title">`+ post.node.title +`</h2>
                                    <p class="post-description">`+ post.node.blog.zajawka.slice(0, 140) + '...' +`</p>
                                    <span class="read-more">Czytaj dalej</span>
                                  </div>
                              </a>
                            </div>
                          </div>
                        </div>`;
          });

          document.querySelector('.posts-container').innerHTML += html;
        }
      }
    });
  }

  return (
    <Layout>
      <Seo
        title="Blog - Rankingdobrychpolis.pl"
        description="W naszych artykułach tłumaczymy, na czym polegają poszczególne rozwiązania, na co zwrócić uwagę przed zakupem polisy czy kto i kiedy powinien skorzystać z ochrony ubezpieczeniowej."
        keywords="polisa ubezpieczeniowa, zakup polisy, blog"
        pathname="/blog/"
      />
      <Hero
        heading="Blog"
        paragraphs={[
          '<strong>Jeśli chcesz dowiedzieć się więcej o ubezpieczeniach, sprawdź nasze artykuły.</strong>',
          'Tłumaczymy w nich, na czym polegają poszczególne rozwiązania, na co zwrócić uwagę przed zakupem polisy czy kto i kiedy powinien skorzystać z ochrony ubezpieczeniowej. Analizujemy oferty towarzystw ubezpieczeniowych i doradzamy, jakie ubezpieczenie wybrać, by odpowiadało konkretnym potrzebom i oczekiwaniom.',
        ]}
        img={
          <StaticImage
            src="../assets/images/about/how-work.png"
            loading="eager"
            width={650}
            quality={90}
            placeholder="none"
            alt="Ranking dobrych polis na życie"
            title="Ranking dobrych polis na życie"
          />
        }
      />
        <Container style={{ marginBottom: '50px' }}>
          <Row>
            <Col lg={8}>
              <Row className="posts-container infinite-scroll">

                {data.allWpPost.edges.slice(0, articlesCount).map((post, index) => {
                  const image = getImage(post.node.featuredImage.node.localFile)
                  return  <div className="col-md-6 article-box">
                            <div className="article">
                              <a href={`/blog/${post.node.slug}/`} title={post.node.title}>
                                  {post.node.featuredImage && (
                                      <div className="post-img" style={{ backgroundImage: `url(${image.images.fallback.src})` }} />
                                  )}
                                  <div className="post-content">
                                    <div className="post-info">
                                        <span className="add-date">{post.node.dateGmt}</span>
                                        <span className="edit-date">{post.node.modifiedGmt}</span>
                                    </div>
                                    <h2 className="post-title">{post.node.title}</h2>
                                    <p className="post-description">{post.node.blog.zajawka.slice(0, 140) + '...'}</p>
                                    <span className="read-more">Czytaj dalej</span>
                                  </div>
                              </a>
                            </div>
                          </div>
                  })}
              </Row>
            </Col>
            <Col lg={4}>
              <StickyBox />
            </Col>
          </Row>
        </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPosts {
    allWpPost(
      sort: { order: DESC, fields: dateGmt }
      filter: { categories: { nodes: { elemMatch: { slug: { ne: "towarzystwo-ubezpieczeniowe" } } } } }
    ) {
      edges {
        node {
          dateGmt(formatString: "DD.MM.YYYY")
          id
          title
          slug
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
          modifiedGmt(formatString: "DD.MM.YYYY")
          blog {
            zajawka
          }
        }
      }
    }
  }
`
