import React from 'react'
import Button from '../Button/Button'
import config from '../../../config.json'
import { default as Sticky} from 'react-sticky-box'
import WeightImg from '../../assets/images/icons/weight-icon.png'
import { Wrapper, HeadingH2, Description } from './StickyBox.style'

export default function StickyBox({isLife = true, isHousehold = false, isHealth = false}){

    let stickyBoxHeadingText, link;

    if(isLife){
      stickyBoxHeadingText = "Wypełnij formularz i porównaj oferty ubezpieczeń na życie";
      link = config.CALC_LINK_LIFE;
    }
    if(isHousehold){
      stickyBoxHeadingText = "Wypełnij formularz i porównaj oferty ubezpieczeń nieruchomości";
      link = config.CALC_LINK_HH;
    }
    if(isHealth){
      stickyBoxHeadingText = "Wypełnij formularz i porównaj oferty ubezpieczeń zdrowotnych";
      link = config.CALC_LINK_HEALTH;
    }

    return (
    <Sticky offsetTop={20} offsetBottom={20}>
        <Wrapper>
          <img
            className="mb-3 image"
            src={WeightImg}
            alt="Ikona waga"
            title="Porównaj oferty ubezpieczeń"
          />
          <HeadingH2>
            {stickyBoxHeadingText}
          </HeadingH2>
          <Description>
            Sprawdź z nami dopasowane oferty!
          </Description>
          <Button text="Przejdź dalej" externalLink={link} padding="15px 40px 15px 40px"/>
        </Wrapper>
    </Sticky>
  )
}
