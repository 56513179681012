import styled from 'styled-components'
import { fontSize } from '../../assets/styles/variables'

export const Wrapper = styled.div`
  background-color: #053592;
  padding: 30px;
  border-radius: 7px;
`

export const HeadingH2 = styled.h2`
  font-size: ${fontSize.h3};
  font-weight: 700;
  color: #fff;
  margin-top: 0;
`

export const Description = styled.p`
  margin-top: 0;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
`
